<template>
	<div class="h-100">
		<b-card :title="article" class="mb-1 h-100 shadow">

			<div class="direction" :title="direction.title">
				<b-icon :icon="direction.iconFrom" variant="success" font-scale="2"></b-icon>
				<b-icon icon="arrow-right" variant="success" font-scale="2"></b-icon>
				<b-icon :icon="direction.iconTo" variant="success" font-scale="2"></b-icon>
			</div>
			<b-card-text>				
				<div>{{ name }}</div>
				<div>{{ pprFinish }}</div>
				<div>{{ tpa }}</div>
				<div v-if="fromSeat || this.move.reciever!='Производство'">{{ fromSeat }}</div>
				<div v-else><b-alert show variant="danger">Не заполнено место хранения прессформы!</b-alert></div>

			</b-card-text>
			<div v-if="move.ОкончаниеРемонта">
				<b-button class="mr-1" variant="success" @click="showModal">Переместить</b-button>
				<b-button class="mr-1" variant="danger" @click="hideMove">Скрыть</b-button>				
			</div>
		</b-card>
	</div>
</template>

<script>
export default{
	name: "MoveCard",
	props: {
		move: Object		
	},
	computed: {
		article: function(){
			return this.move.Артикул
		},
		name: function(){
			return this.move.Наименование
		},
		pprFinish: function(){
			if(this.move.ОкончаниеРемонта)
				return 'Окончание ремонта ' + this.move.ОкончаниеРемонта.replace('T',' ')
			else return ''
		},
		tpa: function(){
			if(this.move.ТПАНомер)
				return 'ТПА ' + this.move.ТПАНомер
			else return ''
		},
		fromSeat: function(){
			if(this.move.МестоХранения)
				return 'Место хранения ' + this.move.МестоХранения
			else return ''			
		},
		direction: function(){			
			let direction = Object
			let title = ''
			let iconFrom = ''
			let iconTo = ''
			if(this.move.sender=='Инструментальный цех'){
				title = title + 'Из инструментально цеха '
				iconFrom = 'wrench'
			}
			if(this.move.sender=='Склад прессформ') {
				title = title + 'Со склада прессформ '
				iconFrom = 'bookshelf'
			}
			if(this.move.sender=='Производство') {
				title = title + 'С производства '
				iconFrom = 'gear-wide'
			}

			if(this.move.reciever=='Инструментальный цех'){
				title = title + 'в инструментальный цех'
				iconTo = 'wrench'
			}
			if(this.move.reciever=='Склад прессформ'){
				title = title + 'на склад прессформ'
				iconTo = 'bookshelf'
			}
			if(this.move.reciever=='Производство'){
				title = title + 'на производство'
				iconTo = 'gear-wide'
			}

			direction.title = title
			direction.iconFrom = iconFrom
			direction.iconTo = iconTo

			return direction
		}
	},
	methods: {
		showModal() {
			this.$root.$emit('showModalMovePF', this.move)
		},
		hideMove(){
			this.$store.dispatch('hideMove', this.move)
		}
	}
}
</script>

<style scoped>
.direction{
    position: absolute;
    right: 10px;
    top: 10px;
}
</style>