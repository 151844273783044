<template>
	<main class="my-3">
		<Settings :names="names" :areas="areas"/>
		<div v-if="loading" class="d-flex justify-content-center mb-3">
			<b-spinner style="width: 5rem; height: 5rem;" label="Загрузка..."></b-spinner>
		</div>
		<CallsList :service="this.$route.meta.service"/>
		<TasksList v-if="tasks.length" :tasks="tasks"/>
		<ToTopButton/>
	</main>
</template>

<script>
	import Settings from '@/components/Settings.vue'
	import CallsList from '@/components/CallsList.vue'
	import TasksList from '@/components/TasksList.vue'
	import ToTopButton from '@/components/ToTopButton.vue'
	export default{
		components: {
			Settings,CallsList,TasksList,ToTopButton
		},
		computed: {
			names: function() {
				return this.$store.state.dicts.Наладчики
			},
			tasks: function() {
				return this.$store.state.tasks
			},
			areas: function(){
				return this.$store.state.areas
			},
			loading: function(){
				return this.$store.state.loading
			}
		},
	}
</script>

<style scoped>
</style>