<template>
	<nav>
		<b-row align-h="between" class="mx-3">
			<div>
				<button @click="refresh"><b-icon-arrow-repeat font-scale="2" title="Обновить задания"/></button>
			</div>
		</b-row>
	</nav>
</template>

<script>
export default{
	name: 'Settings',
	methods:{
		refresh: function(){
			this.$store.dispatch('getDicts')
			this.$store.dispatch('getMoves')
		},
	}
}
</script>

<style scoped>
button{
	margin: 0;
	padding: 0;
	background: none;
	border: 0;
	color: black;
}
</style>