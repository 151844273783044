<template>
	<div>
		<b-row v-if="!minimized">
			<b-col v-show="(!area || (area && task.Участок==area)) && (!task.completed || completedVisible && task.completed) && (!nl || nl==brigadier || nl && (task.НазначенЗавес_Сотрудник_guid==nl || task.НазначенЗапуск_Сотрудник_guid==nl))" v-for="task in tasks" :key="task.guid" cols="12" md="6" xl="4" class="mt-3">
				<TaskCard :task="task"/>				
			</b-col>
		</b-row>
		<b-row v-if="minimized">
			<b-col v-show="(!area || (area && task.Участок==area)) && (!task.completed || completedVisible && task.completed) && (!nl || nl==brigadier || nl && (task.НазначенЗавес_Сотрудник_guid==nl || task.НазначенЗапуск_Сотрудник_guid==nl))" v-for="task in tasks" :key="task.guid" cols="6" md="3" xl="2" class="mt-3 minimized">					
				<TaskCardMinimized :task="task"/>
			</b-col>		
		</b-row>
	</div>

</template>

<script>
import TaskCard from '@/components/TaskCard.vue'
import TaskCardMinimized from '@/components/TaskCardMinimized.vue'
export default{
	name: "TasksList",
	props: {
		tasks: Array,
	},
	components: {
		TaskCard, TaskCardMinimized
	},
	computed: {
		area: function() {
			return this.$store.state.area
		},
		completedVisible: function(){
			return this.$store.state.completedVisible
		},
		minimized: function(){
			return this.$store.state.minimized
		},
		nl: function(){
			return this.$store.state.nl
		},
		brigadier: function(){
			return this.$store.state.brigadier
		},
	}
}
</script>

<style scoped>
.minimized{
	font-size: 0.7rem;
}
</style>