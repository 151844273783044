<template>
	<nav>		
		<b-sidebar v-model="settingsOpened" id="sidebar-right" title="Настройки" backdrop right shadow>
			<b-form class="p-2">
				<b-form-group label="Сотрудник:">
					<b-select class="form-control" v-model="nl" :options="names" value-field="guid" html-field="ФИО" @change="saveSettings"></b-select>
				</b-form-group>
			</b-form>
			<b-form class="p-2">
				<b-form-group label="Участок:">
					<b-select class="form-control" v-model="area" :options="areas" @change="saveSettings"></b-select>
				</b-form-group>
			</b-form>
		</b-sidebar>

		<b-row align-h="between" class="mx-3">
			<div>
				<button @click="toggleVisibility">
					<b-icon-eye-fill v-if="!completedVisible" font-scale="2" title="Показать завершенные"/>
					<b-icon-eye-slash-fill v-if="completedVisible" font-scale="2" title="Скрыть завершенные"/>
				</button>
			</div>
			<div>
				<button @click="toggleMinimized">
					<b-icon-arrows-collapse v-if="!minimized" font-scale="2" title="Включить компактный режим"/>
					<b-icon-arrows-expand v-if="minimized" font-scale="2" title="Выключить компактный режим"/>
				</button>				
			</div>
			<div>
				<button @click="refresh"><b-icon-arrow-repeat font-scale="2" title="Обновить задания"/></button>
			</div>
			<div>
				<button v-b-modal.modal-start-ppr><b-icon-wrench font-scale="2" title="Запустить ремонт/вызов ОТК"/></button>
				<b-modal
				id="modal-start-ppr"
				title="Запустить ремонт/вызов ОТК"
				ok-title="Запустить"
				cancel-title="Отмена"
				@hidden="selectedTPA=null;repairReason=null;repairType=null"
				@ok="startRepair"
				:ok-disabled="!selectedTPA">
					<p class="my-4">
						<b-form-select v-model="selectedTPA" value-field="ТПАGUID" :options="this.$store.state.dicts.Участки" text-field="name" options-field="ТПА"></b-form-select>
						<b-form-select v-model="repairType" :options="repairTypes"></b-form-select>
						<b-form-select v-if="repairType && repairType.includes('Вызов ОТК')" v-model="OTKReason" :options="OTKReasons"></b-form-select>
						<b-form-input v-if="repairType && (repairType.includes('Аварийный') || repairType.includes('Отложенный'))" v-model="repairReason" placeholder="Введите причину ремонта"></b-form-input>
						<div v-if="selectedPF && selectedPF.artikul && repairType && (repairType.includes('Аварийный') || repairType=='Отложенный по пресс-форме')">
							Установлена прессформа {{ selectedPF.artikul }}
							<b-alert v-for="delayed in selectedPF.Отложено" show variant="info">{{ delayed.delayed_remont_comment }}</b-alert>
						</div>
					</p>
				</b-modal>
			</div>
			<div>
				<button v-b-toggle.sidebar-right title="Открыть настройки"><b-icon-gear font-scale="2"/></button>
			</div>
		</b-row>

	</nav>
</template>

<script>
import axios from 'axios'
import VueAxios from 'vue-axios'

export default {
	name: 'Settings',
	data() {
		return {
			selectedTPA: null,
			selectedPF: null,
			repairTypes: [{value: null, text:"Выберите тип ремонта", disabled: true}, "ППР на ТПА","Аварийный на ТПА","Аварийный со снятием","Отложенный по пресс-форме","Отложенный по оборудованию","Вызов ОТК"],
			repairType: null,
			repairReason: null,
			OTKReasons: [{value: null, text:"Выберите причину вызова", disabled: true}, "Запуск", "Переход цвета", "Дефект устранён"],
			OTKReason: null
		}
	},
	props: {
		names: Array,
		areas: Array
	},
	mounted: function(){
		this.getSettings()		
	},
	computed: {
		nl: {
			get(){
				return this.$store.state.nl
			},
			set(value){
				if(this.$store.state.nl!=value){
					this.$store.commit('setNl', value)
				}				
			}
		},
		area: {
			get(){
				return this.$store.state.area
			},
			set(value){
				if(this.$store.state.area!=value){
					this.$store.commit('setArea', value)
				}				
			}
		},
		settingsOpened: {
			get(){
				return this.$store.state.settingsOpened
			},
			set(value){
				this.$store.commit('setSettingsOpened', value)
			}
		},
		minimized: function(){
			return this.$store.state.minimized
		},
		completedVisible: function(){
			return this.$store.state.completedVisible
		}
	},
	watch: {
		selectedTPA: function(val){
			let self = this
			axios
			.get('https://nl.9330077.ru/getTPA.php?tpa='+val)
			.then(function (response) {
				self.selectedPF = response.data
			})
		}
	},
	methods:{
		toggleVisibility: function(){
			this.$store.commit('setVisibility', !this.completedVisible)
		},
		toggleMinimized: function(){			
			this.$store.commit('setMinimized', !this.minimized)
		},
		saveSettings: function(){
			let settings = {nl:this.nl, area:this.area}
			let parsed = JSON.stringify(settings)
			localStorage.setItem('settings', parsed)
		},
		getSettings: function(){			
			if (localStorage.getItem('settings')) {
				let settings = JSON.parse(localStorage.getItem('settings'))
				this.$store.commit('setNl', settings.nl)
				this.$store.commit('setArea', settings.area)
			}
		},
		refresh: function(){
			this.$store.dispatch('getDicts')
			this.$store.dispatch('getTasks')
		},
		startRepair: function(){
			if(this.repairType=='ППР на ТПА') {
				this.$store.dispatch('startManualPPR', this.selectedTPA)
			} else if (this.repairType=='Отложенный по пресс-форме'){
				this.$store.dispatch('startDelayedRepair', {mold_guid: this.selectedPF.mold_guid, repairType: this.repairType, repairReason: this.repairReason, TPA: this.selectedPF.TPA})
			} else if(this.repairType=='Отложенный по оборудованию') {
				this.$store.dispatch('startDelayedRepair', {repairType: this.repairType, repairReason: this.repairReason, TPA: this.selectedPF.TPA, tpa_guid: this.selectedTPA})
			} else if(this.repairType=='Вызов ОТК'){
				this.$store.dispatch('callOTK', {TPA: this.selectedTPA, OTKReason: this.OTKReason})
			} else {
				this.$store.dispatch('startRepair', {mold_guid: this.selectedPF.mold_guid, repairType: this.repairType, repairReason: this.repairReason, TPA: this.selectedPF.TPA})
			}
			this.selectedTPA = null
			this.selectedPF = null
			this.repairType = null
			this.repairReason = null
			this.OTKReason = null
		}
	}
}
</script>

<style scoped>
button{
	margin: 0;
	padding: 0;
	background: none;
	border: 0;
	color: black;
}
</style>