<template>
	<main class="my-3">
		<div v-if="loading" class="d-flex justify-content-center mb-3">
			<b-spinner style="width: 5rem; height: 5rem;" label="Загрузка..."></b-spinner>
		</div>		
		<IdlesList :list="list"/>
		<ToTopButton/>
	</main>
</template>

<script>
	import ToTopButton from '@/components/ToTopButton.vue'
	import IdlesList from '@/components/IdlesList.vue'

	import axios from 'axios'
	import VueAxios from 'vue-axios'

	export default{
		name: 'IdlesReport',
		components: {
			ToTopButton,IdlesList
		},
		mounted() {
			this.getReport()
		},
		data: function(){
			return {
				list: []
			}
		},
		computed: {
			loading: function(){
				return this.$store.state.loading
			}
		},
		methods: {			
			getReport: function(){
				let self = this
				axios
				.get('https://nl.9330077.ru/reports/getIdleList.php')
				.then(function (response) {
					self.list = response.data
				})
			}
		}
	}
</script>

<style scoped>
</style>