<template>
	<button id="to-top-btn" type="button" class="btn btn-light to-top-btn" @click="toTop">
		<b-icon icon="chevron-up" font-scale="1"></b-icon>
	</button>
</template>

<script>
export default{
	name: "ToTopButton",
	mounted: function(){
		window.onscroll = function() {
			let button = document.getElementById("to-top-btn")
			if(button==null) return
			if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
				button.style.display = 'block'
			} else {
				button.style.display = 'none'
			}
		}
	},
	methods: {
		toTop: function(){
			window.scrollTo({top: 0, behavior: 'smooth'})
		}
	}
}
	
</script>

<style scoped>
.to-top-btn{
	position: fixed;
	right: 25px;
	bottom: 40px;
	display: none;
	z-index: 1;
	animation: fadeIn 1s forwards;
}
@keyframes fadeIn {
  0%   { opacity: 0;}
  100% { opacity: 1;}
}
</style>