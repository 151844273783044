<template>
	<main class="my-3">
		<Settings/>
		<div v-if="loading" class="d-flex justify-content-center mb-3">
			<b-spinner style="width: 5rem; height: 5rem;" label="Загрузка..."></b-spinner>
		</div>
		<MovesList v-if="moves.length" :moves="moves"/>
		<ToTopButton/>
	</main>
</template>

<script>
import Settings from '@/components/SkladPFSettings.vue'
import MovesList from '@/components/MovesList.vue'
import ToTopButton from '@/components/ToTopButton.vue'

export default{
	components: {
		Settings, MovesList,ToTopButton
	},
	computed: {
		moves: function() {
			return this.$store.state.moves
		},
		loading: function(){
			return this.$store.state.loading
		}
	},
}
</script>