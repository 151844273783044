<template>
	<div class="">
		<b-row v-if="calls.length">
			<b-col v-for="call in calls" :key="call.id" cols="12" md="4" xl="3" class="mt-3">
				<CallCard :call="call"/>
			</b-col>
		</b-row>
		<b-row v-else>
			<b-col v-if="this.$route.meta.service!=7 && this.$route.meta.service!=2">
				Вызовы отсутствуют
			</b-col>
		</b-row>
	</div>
</template>

<script>
import CallCard from '@/components/CallCard.vue'
export default {
	name: "CallsList",
	props: {
		service: Number
	},
	components: {
		CallCard
	},
	computed: {
		calls() {
			return this.$store.state.calls.filter((call) => call.service == this.service)
		},
		serviceName(){
			if(this.$store.state.dicts.Службы){
				let service = this.$store.state.dicts.Службы.filter((service) => service.id == this.service)[0]
				return service.name
			}
			return null
		}
	},
	methods: {}
}
</script>

<style scoped>
	
</style>