<template>
	<div class="h-100">
		<b-card :title="'ТПА №'+task.ТПАНомер" class="mb-1 h-100 shadow">
		<b-card-text>
			<div class="urgent">
				<a class="timea" :id="task.guid" href="javascript:" v-if="task.СтатусЗамены!='Отмена'" title="Время выполнения задания">{{ createTime }} мин.</a>
				<b-popover v-if="task.СтатусЗамены!='Отмена'&&!task.completed" :target="task.guid" triggers="click blur" placement="bottom">
					<template #title>Время выполнения задания</template>
					<div>От создания мастером {{ createTime }} мин.</div>
					<div>{{ fromHangTime }}</div>
					<div>{{ fromLaunchTime }}</div>
				</b-popover>
				<b-icon v-if="task.Приоритет=='Да'" icon="lightning-fill" variant="danger" font-scale="2" title="Срочно!"></b-icon>
			</div>
			<div>{{ task.Участок }}</div>
			<div>{{ task.Прессформа }} {{ task.ПрессформаАртикул }}</div>
			<div>{{ task.СтатусЗамены }}</div>
			<div>{{ dateFromSZ }}</div>
			<div v-if="task.СтатусЗамены!='Отмена'">
				<div v-if="task.НазначенЗавес_СотрудникФИО"><TaskStatusIcon :status="task.СтатусЗамены" :type="'hang'"/>
					<span v-if="task.stage<3">Завешивает</span><span v-if="task.stage>=3">Завесил</span> {{ hangerShort }}
					<b-icon class="clear-icon" v-if="task.stage==2" font-scale="1.3" icon="person-x" @click="change(task,'clearhanger')" title="Снять сотрудника с операции"></b-icon>
					<span v-if="task.completed">за {{ hangTime }} минут</span>
				</div>
				<div v-if="task.НазначенЗапуск_СотрудникФИО"><TaskStatusIcon :status="task.СтатусЗамены" :type="'launch'"/>
					<span v-if="!task.completed">Запускает</span> <span v-if="task.completed">Запустил</span> {{ launcherShort }}
					<b-icon class="clear-icon" v-if="task.stage==4" font-scale="1.3" icon="person-x" @click="change(task,'clearlauncher')" title="Снять сотрудника с операции"></b-icon>
					<span v-if="task.completed">за {{ launchTime }} минут</span>
				</div>
			</div>
		</b-card-text>
		<div v-show="nl && !task.completed">
			<b-form>
				<b-form-group label="Завесит:" v-if="!task.НазначенЗавес_Сотрудник_guid && task.ВидЗадания!='Запуск без завеса'">
					<b-select class="form-control" v-model="hanger" :options="names" value-field="guid" html-field="ФИО"></b-select>
				</b-form-group>
				<b-form-group label="Запустит:" v-if="!task.НазначенЗапуск_Сотрудник_guid">
					<b-select class="form-control" v-model="launcher" :options="names" value-field="guid" html-field="ФИО"></b-select>
				</b-form-group>
			</b-form>
		</div>
		<b-alert show v-if="task.Примечание || task.ВидЗадания!='Завес и запуск'" variant="warning">{{ task.ВидЗадания }}. {{ task.Примечание }}</b-alert>
		<div v-show="nl">
			<b-button class="mr-1 mt-1" :disabled="!hanger || disabled" v-if="task.stage==1" variant="primary" @click="change(task,'start')">Начать работу</b-button>
			<b-button class="mr-1 mt-1" :disabled="disabled" v-if="task.stage==2" variant="primary" @click="change(task,'hang')">Завершить завес</b-button>
			<b-button class="mr-1 mt-1" :disabled="!launcher || disabled" v-if="task.stage==3" variant="success" @click="change(task,'startlaunch')">Начать запуск</b-button>
			<b-button class="mr-1 mt-1 d-none" :disabled="disabled" v-if="task.stage==4 && task.ВидЗадания=='Завес без запуска'" variant="primary" @click="change(task,'finishtest')">Завершить тестовый запуск</b-button>
			<b-button class="mr-1 mt-1" :disabled="disabled" v-if="task.stage==4" variant="primary" @click="change(task,'launch')">Завершить запуск</b-button>
			<b-button class="mr-1 mt-1" :disabled="disabled" v-if="needOn" variant="primary" @click="change(task,'on')">Включить</b-button>
			<b-dropdown right text="Отмена" class="mr-1 mt-1" v-if="!task.completed && !needOn" variant="danger">				
				<b-dropdown-item class="mr-1" variant="danger" @click="change(task,'cancel')">Ошибка</b-dropdown-item>
				<b-dropdown-item class="mr-1" variant="danger" @click="change(task,'repair_pf')">Ремонт п/ф</b-dropdown-item>
				<b-dropdown-item class="mr-1" variant="danger" @click="change(task,'repair_pf_wop')">Ремонт п/ф без снятия</b-dropdown-item>
			</b-dropdown>
			<b-button class="mr-1" :disabled="disabled" v-if="task.СтатусЗамены=='Отмена' || task.СтатусЗамены=='Ремонт'" variant="success" @click="change(task,'restart')">Возобновить</b-button>
		</div>
	</b-card>
</div>
</template>

<script>
	import TaskStatusIcon from '@/components/TaskStatusIcon.vue'
	export default{
		name: "TaskCard",
		props: {
			task: Object,
		},
		data: function(){
			return {
				hanger: null,
				launcher: null,
				disabled: false
			}
		},
		computed: {
			nl: function(){
				return this.$store.state.nl
			},
			dateFromSZ: function(){
				if(this.task.ВремяНачалаПоСЗ && this.task.ВремяНачалаПоСЗ!='0001-01-01T00:00:00'){
					return 'Время начала по СЗ '+this.task.ВремяНачалаПоСЗ.replace('T',' ')
				} else {
					return null
				}				
			},
			names: function() {
				let names = []
				if(this.$store.state.dicts.Наладчики){			
					this.$store.state.dicts.Наладчики.forEach((element) => {
						if(element.НазначенныеТПА && element.НазначенныеТПА.length){
							element.disabled = true
						}
						names.push(element)
					})
					return names
				}
				return null
			},
			createTime: function(){
				if(!this.completed){
					return Math.round( (Date.now() - new Date(this.task.ВремяВыдачиЗаданияНаладчикам))/60000)
				} else {
					return Math.round( (new Date(this.task.ВыполненЗапуск_Время) - new Date(this.task.ВремяВыдачиЗаданияНаладчикам))/60000)
				}
			},
			hangTime: function(){
				return Math.round( (new Date(this.task.ВыполненЗавес_Время) - new Date(this.task.НазначенЗавес_Время))/60000)
			},
			launchTime: function(){
				return Math.round( (new Date(this.task.ВыполненЗапуск_Время) - new Date(this.task.НазначенЗапуск_Время))/60000)
			},
			fromHangTime: function(){
				let value
				if(this.task.stage==2){
					value = Math.round( (Date.now() - new Date(this.task.НазначенЗавес_Время))/60000)
				} else if(this.task.stage>2){
					value = Math.round( (new Date(this.task.ВыполненЗавес_Время) - new Date(this.task.НазначенЗавес_Время))/60000)
				}
				let str = 'Время завеса ' + value + ' мин.'
				return value>=0&&value<5000?str:null
			},
			fromLaunchTime: function(){
				let value
				if(this.task.stage==4){
					value = Math.round( (Date.now() - new Date(this.task.НазначенЗапуск_Время))/60000)
				} else if(this.task.stage>4) {
					value = Math.round( (new Date(this.task.ВыполненЗапуск_Время) - new Date(this.task.НазначенЗапуск_Время))/60000)
				}
				let str = 'Время запуска ' + value + ' мин.'
				return value>=0&&value<5000?str:null
			},
			hangerShort: function(){
				return this.task.НазначенЗавес_СотрудникФИО.replace(/(\S+) (\S)\S* (\S)\S*/, "$1 $2.$3.")
			},
			launcherShort: function(){
				return this.task.НазначенЗапуск_СотрудникФИО.replace(/(\S+) (\S)\S* (\S)\S*/, "$1 $2.$3.")
			},
			needOn: function(){
				return this.task.НужноВключение=='Да'
			},
		},
		methods: {
			change: function(task, action){
				this.disabled = true
				task.test = false
				task.wop = false
				if(action=='cancel' || action=='clearhanger'){
					this.hanger = null
					this.launcher = null
				} else if(action=='clearlauncher') {
					this.launcher = null
				} else if(action=='finishtest'){
					action = 'launch'
					task.test = true
				} else if(action=='repair_pf_wop'){
					action = 'repair_pf'
					task.wop = true
				}
				task.newStatus = action
				task.hanger = this.hanger
				task.launcher = this.launcher
				let self = this
				this.$store.dispatch('saveTask', task).then(function(){
					self.disabled = false
				})
			},
		},
		components: {
			TaskStatusIcon
		},
	}
</script>

<style scoped>
.urgent{
	position: absolute;
	top: 10px;
	right: 10px;
}
.timea{
	color: black !important;
	text-decoration: none !important;
}
.clear-icon{
	cursor: pointer;
}
</style>