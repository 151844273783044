<template>
	<span class="mr-1">
		<b-icon v-if="type=='hang' && status=='Взято в работу'" animation="fade" icon="caret-right-square" variant="success" title="Завес выполняется"></b-icon>
		<b-icon v-if="type=='hang' && (status=='Выполнен завес' || status=='Завершено')" icon="check-square" variant="success" title="Завес завершен"></b-icon>
		<b-icon v-if="type=='launch' && status=='Взято в работу'" icon="square" title="Запуск еще не начался"></b-icon>		
		<b-icon v-if="type=='launch' && status=='Выполнен завес'" animation="fade" icon="caret-right-square" variant="success" title="Запуск выполняется"></b-icon>
		<b-icon v-if="type=='launch' && status=='Завершено'" icon="check-square" variant="success" title="Запуск завершен"></b-icon>
	</span>	
</template>

<script>
	export default{
		name: "TaskStatusIcon",
		props: {			
			status: String,
			type: String
		}
	}
</script>