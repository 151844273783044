<template>
	<div>
		<b-row>
			<b-col v-for="repair in repairs" :key="repair.remont_guid" cols="12" md="6" xl="3" class="mt-3">
				<RepairCard :repair="repair"/>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import RepairCard from '@/components/RepairCard.vue'
export default{
	name: "RepairsList",
	props: {
		repairs: Array,
	},
	components: {
		RepairCard
	},
}
</script>