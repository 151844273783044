<template>
	<div>
		<h1>Список станков в простое</h1>
		<div v-for="idle in list">
			ТПА {{ idle.tpa }}, прессформа {{ idle.artikul }}
		</div>		
	</div>
</template>

<script>
	export default{
		props: {
			list: []
		}
	}
</script>