<template>
	<div>
		<b-row>
			<b-col v-for="move in moves" :key="move.pf_guid" cols="12" md="6" xl="4" class="mt-3">
				<MoveCard :move="move"/>
			</b-col>
		</b-row>
		<b-modal
			id="modal-move-pf"
			:title="'Переместить прессформу '+currentCard.Артикул+' на склад'"
			ok-title="ОК"
			:ok-disabled="!seat"
			cancel-title="Отмена"
			@ok="save('putin')">
				<v-select
					:options="seats"
					v-model="seat"
					label="Наименование"
					:reduce="(option) => option.МестоХраненияGUID"
					:selectable = "(option) => !option.disabled">
					<div slot="no-options">Ячейка не найдена</div>
				</v-select>		
		</b-modal>
	</div>
</template>

<script>
import MoveCard from '@/components/MoveCard.vue'
export default{
	name: "MovesList",
	mounted: function(){
		var self = this
		this.$root.$on('showModalMovePF', function (currentCard) {
			this.$store.dispatch('getDicts')			
			self.seat = null
			self.currentCard = currentCard
			this.$root.$emit('bv::show::modal', 'modal-move-pf')
		})
	},
	props: {
		moves: Array,
	},
	components: {
		MoveCard
	},
	data: function(){
		return {
			currentCard: {},
			seat: null
		}
	},
	computed:{
		seats: function(){
			let seats = []
			seats.unshift({МестоХраненияGUID:null,Наименование:"Выберите место",disabled: true})
			if(this.$store.state.dicts.МестаХранения){			
				this.$store.state.dicts.МестаХранения.forEach((element) => {
					if(element.Пустая) seats.push(element)
				})
			}
			return seats
		}
	},
	methods: {
		save: function(action){			
			this.currentCard.seat_guid=this.seat
			this.currentCard.action=action
			this.$store.dispatch('saveMove', this.currentCard)
		}
	},
}
</script>