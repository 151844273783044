<template>
	<main class="my-3">
		<Settings/>
		<div v-if="loading" class="d-flex justify-content-center mb-3">
			<b-spinner style="width: 5rem; height: 5rem;" label="Загрузка..."></b-spinner>
		</div>
		<CallsList :service="this.$route.meta.service"/>
		<RepairsList v-if="repairs.length" :repairs="repairs"/>
		<ToTopButton/>
	</main>
</template>

<script>
import Settings from '@/components/PPRSettings.vue'
import CallsList from '@/components/CallsList.vue'
import RepairsList from '@/components/RepairsList.vue'
import ToTopButton from '@/components/ToTopButton.vue'

export default{
	components: {
		Settings,CallsList,RepairsList,ToTopButton
	},
	computed: {
		repairs: function() {
			return this.$store.state.repairs
		},
		loading: function(){
			return this.$store.state.loading
		}
	},
}
</script>