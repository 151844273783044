<template>
	<div class="h-100">
		<b-card 
			:border-variant="variant"
			:header-bg-variant="variant"
			:header="name +' '+ article"
			class="mb-1 h-100 shadow"
			header-class = ""
			>
			<b-card-text>
				<div>{{ comments }}</div>
				<div>{{ date }}</div>
				<div>{{ number }}</div>
				<div v-if="startDate!='0001-01-01T00:00:00'">
					<b-icon animation="fade" icon="caret-right-square" variant="success" title="Ремонт выполняется"></b-icon>
					{{ repairerShort }}
				</div>
				<b-alert v-for="delayed in repair.Отложено" show variant="info">{{ delayed.delayed_remont_comment }}</b-alert>
			</b-card-text>
			<div v-if="startDate=='0001-01-01T00:00:00'" class="d-xl-none">
				<b-form>
					<b-form-group label="Сотрудник:">
						<b-select class="form-control" v-model="repairer" :options="this.$store.state.dicts.Инструментальщики" value-field="guid" html-field="ФИО"></b-select>
					</b-form-group>
				</b-form>
				<b-button class="mr-1" variant="primary" :disabled="!repairer || disabled" @click="change(repair,'start')">Взять в работу</b-button>				
			</div>
			<b-button v-if="startDate!='0001-01-01T00:00:00'" :disabled="disabled" class="mr-1 d-xl-none" variant="primary" @click="change(repair,'finish')">Завершить</b-button>
		</b-card>
	</div>
</template>

<script>
export default{
	name: "RepairCard",
	props: {
		repair: Object,		
	},
	data: function(){
		return {
			repairer: null,
			disabled: false
		}
	},
	computed: {
		name: function(){
			return this.repair.ОснасткаОборудованиеНаименование
		},
		article: function(){
			return this.repair.ОснасткаОборудованиеАртикул
		},
		comments: function(){
			return this.repair.Комментарий
		},
		date: function(){
			return this.repair.Дата.replace('T',' ')
		},
		startDate: function(){
			return this.repair.ФактическаяДатаНачалаРемонта
		},
		number: function(){
			return this.repair.Номер
		},
		repairerShort: function(){
			return this.repair.repairer_fio.replace(/(\S+) (\S)\S* (\S)\S*/, "$1 $2.$3.")
		},
		variant: function(){
			if(this.repair.ТипРемонта=='ППР') return 'warning'
			if(this.repair.ТипРемонта=='Аварийный') return 'danger'
		}
	},
	methods: {
		change: function(repair, action){
			this.disabled = true
			repair.action = action
			repair.repairer = this.repairer
			let self = this
			this.$store.dispatch('saveRepair', repair).then(function(){
				self.disabled = false
			})
		},
	},
}
</script>

<style scoped>
.bg-warning{
	background-color: #ffc1074f !important;
}
.bg-danger {
    background-color: #dc354552 !important;
}
.card-header{
	font-size: x-large;
}
</style>