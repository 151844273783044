import Vue from 'vue'
import VueRouter from 'vue-router'

import Nl from '@/views/Nl.vue'
import SkladPF from '@/views/SkladPF.vue'
import PPR from '@/views/PPR.vue'
import Master from '@/views/Master.vue'
import OTK from '@/views/OTK.vue'
import Complect from '@/views/Complect.vue'
import El from '@/views/El.vue'
import Mech from '@/views/Mech.vue'
import Footer from '@/views/Footer.vue'
import IdlesReport from '@/views/IdlesReport.vue'

Vue.use(VueRouter)

const routesNL = [
{
	path: '/',
	name: 'Main',
	meta: {title: 'Перевесы прессформ', service: 2, coworkers: 'Наладчики'},
	components: {
		main: Nl,
		footer: Footer
	}
},
{
	path: '/idles',
	name: 'IdlesReport',
	meta: {title: 'Станки в простое'},
	components: {
		main: IdlesReport,
		footer: Footer
	}
},
]

const routesSkladPF = [
{
	path: '/',
	name: 'SkladPF',
	meta: {title: 'Склад прессформ'},
	components: {
		main: SkladPF,
		footer: Footer
	}
},
]

const routesPPR = [
{
	path: '/',
	name: 'PPR',
	meta: {title: 'Инструментальный цех', service: 7, coworkers: 'Инструментальщики'},
	components: {
		main: PPR,
		footer: Footer
	}
},
]

const routesMaster = [
{
	path: '/',
	name: 'Master',
	meta: {title: 'Служба мастеров', service: 1, coworkers: 'Мастера'},
	components: {
		main: Master,
		footer: Footer
	}
},
]

const routesOTK = [
{
	path: '/',
	name: 'OTK',
	meta: {title: 'Отдел контроля качества', service: 3, coworkers: 'Откашники'},
	components: {
		main: OTK,
		footer: Footer
	}
},
]

const routesComplect = [
{
	path: '/',
	name: 'Complect',
	meta: {title: 'Служба комплектовщиков', service: 4, coworkers: 'Комплектовщики'},
	components: {
		main: Complect,
		footer: Footer
	}
},
]

const routesEl = [
{
	path: '/',
	name: 'El',
	meta: {title: 'Электромонтеры', service: 5, coworkers: 'Электромонтеры'},
	components: {
		main: El,
		footer: Footer
	}
},
]

const routesMech = [
{
	path: '/',
	name: 'Mech',
	meta: {title: 'Служба механиков', service: 6, coworkers: 'Механики'},
	components: {
		main: Mech,
		footer: Footer
	}
},
]

let routes
const host = window.location.host
const parts = host.split('.')
const local = parts[0].includes('localhost') || parts[0].includes('192')
if(local || parts[0]==='nl') routes=routesNL
if(parts[0]==='skladpf') routes=routesSkladPF
if(parts[0]==='ppr') routes=routesPPR
if(parts[0]==='master') routes=routesMaster
if(parts[0]==='otk') routes=routesOTK
if(parts[0]==='complect') routes=routesComplect
if(parts[0]==='el') routes=routesEl
if(parts[0]==='mech') routes=routesMech

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

const DEFAULT_TITLE = 'Наладка'
router.afterEach((to, from) => {
	Vue.nextTick(() => {
		document.title = to.meta.title || DEFAULT_TITLE
	})
})

export default router
