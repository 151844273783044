<template>
	<div id="app">
		<b-container fluid>
			<router-view v-if="this.$store.state.token" name="main" class="main"></router-view>
			<div v-else class="text-center text-md-left">Требуется авторизация</div>
			<router-view name="footer" class="footer"></router-view>
		</b-container>
		<b-modal :visible="alertShow" ok-only @ok="$store.commit('setAlertText','')">{{ alertText }}</b-modal>
	</div>
</template>

<script>
export default{
	mounted: function(){
		this.$store.dispatch('getToken')
		this.$store.dispatch('getDicts')
		this.$store.dispatch('getTasks')
		this.$store.dispatch('getRepairs')
		this.$store.dispatch('getMoves')
		this.$store.dispatch('getCalls')
	},
	computed: {
		alertText: function(){
			return this.$store.state.alertText
		},
		alertShow: function(){
			return this.$store.state.alertText != ""
		}
	},
}
</script>
	<style>
	option:disabled{
		color: #d6d8db;
	}
	body{
		user-select: none;
	}
	main{
		min-height: calc(100vh - 80px);
	}
	.pointer{
		cursor: pointer;
	}
</style>