<template>
	<main class="my-3">	
		<div v-if="loading" class="d-flex justify-content-center mb-3">
			<b-spinner style="width: 5rem; height: 5rem;" label="Загрузка..."></b-spinner>
		</div>
		<CallsList v-else :service="this.$route.meta.service"/>
		<ToTopButton/>
	</main>
</template>

<script>
import CallsList from '@/components/CallsList.vue'
import ToTopButton from '@/components/ToTopButton.vue'

export default{
	components: {
		CallsList, ToTopButton
	},
	computed: {
		loading(){
			return this.$store.state.loading
		},
	},
}
</script>