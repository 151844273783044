<template>
	<div class="h-100">
		<b-card		
		class="mb-1 h-100"
		>
		<b-card-text>
			<b-row>
				<b-col cols="4" title="ТПА" class="text-left">{{ task.ТПАНомер }}</b-col>
				<b-col cols="8" title="Прессформа" class="text-right">{{ task.ПрессформаАртикул }}</b-col>
				<b-col cols="4" title="Статус" class="text-left">
					<div v-show="this.task.СтатусЗамены!='Отмена'" class="text-left">
						<b-iconstack font-scale="1">
							<b-icon stacked icon="circle" variant="success" v-if="task.СтатусЗамены!='Выдано мастером литья'"></b-icon>
							<b-icon stacked icon="circle-fill" variant="success" v-if="task.СтатусЗамены=='Выполнен завес' || task.СтатусЗамены=='Завершено'"></b-icon>
						</b-iconstack>
						<b-iconstack font-scale="1">
							<b-icon stacked icon="circle" variant="success" v-if="task.СтатусЗамены!='Выдано мастером литья'"></b-icon>
							<b-icon stacked icon="circle-fill" variant="success" v-if="task.СтатусЗамены=='Завершено'"></b-icon>
						</b-iconstack>					
					</div>
					<div>
						<b-iconstack font-scale="1" v-if="this.task.СтатусЗамены=='Отмена'">						
							<b-icon stacked icon="circle-fill" variant="danger" v-if="task.СтатусЗамены=='Отмена'"></b-icon>
						</b-iconstack>						
					</div>
				</b-col>
				<b-col cols="8" class="text-right">
					<span v-show="createTime && task.СтатусЗамены!='Отмена'" title="Время выполнения задания">{{ createTime }} мин.</span>
					<b-icon v-if="task.Приоритет=='Да'" icon="lightning-fill" variant="danger" font-scale="2" title="Срочно!"></b-icon>	
				</b-col>
			</b-row>		
		</b-card-text>		
	</b-card>
</div>
</template>

<script>
export default{
	name: "TaskCardMinimized",
	props: {
		task: Object,
	},
	computed: {
		taskCompleted: function(){
			return this.task.СтатусЗамены=='Завершено' || this.task.СтатусЗамены=='Отмена'
		},
		createTime: function(){
			if(!this.taskCompleted){
				return Math.round( (Date.now() - new Date(this.task.ВремяВыдачиЗаданияНаладчикам))/60000)
			} else {
				return Math.round( (new Date(this.task.ВыполненЗапуск_Время) - new Date(this.task.ВремяВыдачиЗаданияНаладчикам))/60000)
			}
		},
	}	
}
</script>

<style scoped>
.card-body {
    padding: 5px;
}
</style>