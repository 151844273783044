<template>
	<div class="h-100">
		<b-card :header="'ТПА №'+ call.machine" class="mb-1 h-100 shadow" :border-variant="variant" :header-bg-variant="variant">
			<b-card-text>
				<div>Вызов №{{call.id}}: {{dateCall}}</div>
				<div v-if="call.date_start">Начало: {{ dateStart }}</div>

				<div class="mt-2" v-if="!appointed">
					<label>Назначить:</label>
					<div class="input-group">
						<b-select class="form-control" v-model="coworker" :options="coworkers" value-field="guid" html-field="ФИО"></b-select>
						<div class="input-group-append">
							<span class="input-group-text pointer" title="Назначить" @click="setCoworker"><b-icon-box-arrow-in-right/></span>
						</div>
					</div>
				</div>
				<div v-else>
					Назначен {{coworkerName}}
				</div>

				<div class="mt-2">
					<label>Служба:</label>
					<div class="input-group">
						<b-select class="form-control" v-model="forwardedService" :options="this.$store.state.dicts.Службы" value-field="id" html-field="name"></b-select>
						<div class="input-group-append">
							<span class="input-group-text pointer" title="Перенаправить" @click="setService"><b-icon-box-arrow-right/></span>
						</div>
					</div>
				</div>
			</b-card-text>
		</b-card>
	</div>
</template>

<script>
export default {
	name: "CallCard",
	mounted(){
		if(this.call.coworker) {
			this.appointed = true
		}
	},
	data(){
		return {
			coworker: null,
			forwardedService: null,
			appointed: false
		}
	},
	props: {
		call: Object		
	},
	computed: {
		variant(){
			if(!this.call.date_start) return 'danger'
			if(this.call.date_start) return 'success'
		},
		dateCall(){
			let date = new Date(this.call.date_call)
			return date.toLocaleTimeString('ru-RU', {hour: '2-digit', minute: '2-digit'})
		},
		dateStart(){
			let date = new Date(this.call.date_start)
			return date.toLocaleTimeString('ru-RU', {hour: '2-digit', minute: '2-digit'})
		},
		coworkers(){
			return this.$store.state.dicts[this.$route.meta.coworkers]
		},
		coworkerName: function(){
			if(!this.coworkers) return ""
			let name = this.coworkers.filter((c)=>c.guid==this.call.coworker)[0].ФИО
			let shortName = name.replace(/(\S+) (\S)\S* (\S)\S*/, "$1 $2.$3.")
			shortName = shortName.replace("&#9733;","")
			return shortName
		},
	},
	methods: {
		setCoworker(){
			if(!this.coworker) return
			this.$store.dispatch('saveCall', {id: this.call.id, sn: this.call.sn, coworker: this.coworker}).then(()=>{
				this.appointed = true
			})
		},
		setService(){
			if(!this.forwardedService || this.call.service==this.forwardedService) return
			this.$store.dispatch('saveCall', {id: this.call.id, sn: this.call.sn, forwardedService: this.forwardedService})
		}
	}
}
</script>

<style scoped>
	
</style>